import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBllBFh-b4H5urtSQqYVgci0HBufL6IVDg",
  authDomain: "newtest-b8ba4.firebaseapp.com",
  databaseURL: "https://newfirstproject-9dd84-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "newtest-b8ba4",
  storageBucket: "newtest-b8ba4.appspot.com",
  messagingSenderId: "54894321614",
  appId: "1:54894321614:web:c227c62a789ea4c1fe559f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { database, firestore, storage };
