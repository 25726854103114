import React from "react";
import { useLocation, useNavigate } from "react-router-dom"

const RedirectLink = () => {
    const navigate = useNavigate();
    const location = useLocation();
            // User Redirect In Home Page using this code
            const handleHome = () => {
                navigate("/myaccount", {state: {id: location.state.id}})
            }
        
            // User Redirect To Logout Page by Using this Code
        
            // User Redirect To ChangePassword Page
            const handlechangePassword = () => {
                navigate("/changepassword", {state: {id: location.state.id}})
            }
        
            // User Redirect To Subsidy Page by Using This Code
        
            // User Redirect TO Download Document by Using This Code
            const handledownloadDocument = () => {
                navigate("/downloaddocument", {state: {id: location.state.id}})
            }
        
            // User Redirect To Invoice Page by Using This Code
            const handleInvoice = () => {
                navigate("/invoice", {state: {id: location.state.id}})
            }
            // User Redirect To Security Page Using This Code
            const handleSecurity = () => {
                navigate("/security", {state: {id: location.state.id}})
            }
        
            // User Redirect to Noc Page by Using This Code
            const handleNoc = () => {
                navigate("/noc", {state: {id: location.state.id}})
            }
            const handleregistration = () => {
                navigate("/registrationfile", {state: {id: location.state.id}})
            }
            const handleDealership = () => {
                navigate("/dealership", {state: {id: location.state.id}})
            }
            const handleLicence = () => {
                navigate("/licence", {state: {id: location.state.id}})
            }
            const handleLateFine = () => {
                navigate("/latefine", {state: {id: location.state.id}})
            }
            const handleInsurancePaper = () => {
                navigate("/insurance", {state: {id: location.state.id}})
            }
    return(
        <div>
            <div className="top-menu-myaccount">
                    <ul>
                                <li>
                                    <a onClick={() => handleHome()} >Home</a>
                                </li>
                                <li>
                                    <a onClick={() => handleregistration()} >Registration</a>
                                </li>
                                <li>
                                    <a onClick={() => handleDealership()} >Dealership</a>
                                </li>
                                <li>
                                    <a onClick={() => handleLicence()} >Licence</a>
                                </li>
                                <li>
                                    <a onClick={() => handleLateFine()} >Late Fine</a>
                                </li>
                                <li>
                                    <a onClick={() => handleInsurancePaper()} >Insurance Paper</a>
                                </li>
                                <li>
                                    <a onClick={() => handleNoc()}>NOC</a>
                                </li>
                                <li>
                                    <a onClick={() => handleSecurity()}>Security Money</a>
                                </li>
                                
                                <li>
                                    <a onClick={() => handleInvoice()}>Invoice</a>
                                </li>
                                <li>
                                    <a onClick={() => handledownloadDocument()}>Download Document</a>
                                </li>
                                
                                <li>
                                    <a onClick={() => handlechangePassword()}>Change Password</a>
                                </li>
                               
                            </ul>
                    </div>
        </div>
    )
}
export default RedirectLink;