import React, { useEffect, useState } from "react";
import { ref, onValue, update } from "firebase/database"; // Use 'update' instead of 'set' to update data
import { useLocation, useNavigate } from "react-router-dom"; // import useNavigate
import { database, storage, firestore } from '../firebase-config'; // Import Firebase storage
import { getDownloadURL, uploadBytes, ref as storageRef } from "firebase/storage"; // Import storage functions
import { collection, getDocs } from "firebase/firestore";

const Submitted = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate for redirection
  const [registrationData, setRegistrationData] = useState(null);
  const [allSubmissions, setAllSubmissions] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
  });
  const [image1, setImage1] = useState(null); // State to hold image 1
  const [image2, setImage2] = useState(null); // State to hold image 2

  // Fetch registration data from Realtime Database based on ID
  useEffect(() => {
    if (location.state?.id) {
      const registrationRef = ref(database, `registrations/${location.state.id}`);
      onValue(registrationRef, (snapshot) => {
        const data = snapshot.val();
        setRegistrationData(data);
        setFormData({
          name: data?.name || "", // Pre-fill form with existing name if available
          mobileNumber: data?.mobileNumber || "", // Pre-fill form with existing mobile number if available
        });
      });
    }
  }, [location.state?.id]);

  // Fetch all Firestore data (formSubmissions collection)
  useEffect(() => {
    const fetchFirestoreData = async () => {
      const querySnapshot = await getDocs(collection(firestore, "formSubmissions"));
      const submissions = [];
      querySnapshot.forEach((doc) => {
        submissions.push({ 
          id: doc.id, 
          publishedDate: doc.data().PublishedDate, 
          category: doc.data().category, 
          title: doc.data().title, 
          rotype: doc.data().rotype 
        });
      });
      setAllSubmissions(submissions);
    };

    fetchFirestoreData();
  }, []);

  // Handle form submission and update data in Realtime Database, including image uploads
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Upload images to Firebase Storage and get the URLs
    let image1URL = null;
    let image2URL = null;

    if (image1) {
      const image1Ref = storageRef(storage, `images/${location.state.id}_image1`);
      await uploadBytes(image1Ref, image1);
      image1URL = await getDownloadURL(image1Ref);
    }

    if (image2) {
      const image2Ref = storageRef(storage, `images/${location.state.id}_image2`);
      await uploadBytes(image2Ref, image2);
      image2URL = await getDownloadURL(image2Ref);
    }

    // Update the data in the Realtime Database
    const registrationRef = ref(database, `registrations/${location.state.id}`);
    await update(registrationRef, {
      name: formData.name,
      mobileNumber: formData.mobileNumber,
      image1URL, // Store the image 1 URL
      image2URL, // Store the image 2 URL
    });

    // Redirect to the next page after updating the data
    navigate("/next-page", { state: { id: location.state.id } }); // Pass the updated ID to the next page
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    if (e.target.name === "image1") {
      setImage1(e.target.files[0]);
    } else if (e.target.name === "image2") {
      setImage2(e.target.files[0]);
    }
  };

  return (
    <div>
      {/* Form Section */}
      <h2>Update Your Details</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="mobileNumber">Mobile Number:</label>
          <input
            type="tel"
            id="mobileNumber"
            name="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="image1">Upload Image 1:</label>
          <input
            type="file"
            id="image1"
            name="image1"
            accept="image/*"
            onChange={handleImageChange}
            required
          />
        </div>
        <div>
          <label htmlFor="image2">Upload Image 2:</label>
          <input
            type="file"
            id="image2"
            name="image2"
            accept="image/*"
            onChange={handleImageChange}
            required
          />
        </div>
        <button type="submit">Update</button>
      </form>

      {/* Display Submitted Data */}
      {registrationData && (
        <div>
          <h2>Submitted Data (Realtime Database)</h2>
          <p>Country: {registrationData.state}</p>
          <p>State: {registrationData.district}</p>
          <p>City: {registrationData.location}</p>
          <p>Name: {registrationData.name}</p>
          <p>Mobile Number: {registrationData.mobileNumber}</p>
        </div>
      )}

      {/* Firestore Data */}
      <h2>All Submissions (Firestore)</h2>
      <ul>
        {allSubmissions.map((submission) => (
          <li key={submission.id}>
            <p>Published Date: {submission.publishedDate}</p>
            <p>Category: {submission.category}</p>
            <p>Title: {submission.title}</p>
            <p>RO Type: {submission.rotype}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Submitted;
