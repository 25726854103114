import React, { useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import { useLocation } from "react-router-dom";
import { database } from "../firebase-config";

const NextPage = () => {
  const location = useLocation();
  const [registrationData, setRegistrationData] = useState(null);

  // Fetch the updated registration data from the Realtime Database
  useEffect(() => {
    if (location.state?.id) {
      const registrationRef = ref(database, `registrations/${location.state.id}`);
      onValue(registrationRef, (snapshot) => {
        setRegistrationData(snapshot.val());
      });
    }
  }, [location.state?.id]);

  return (
    <div>
      <h2>Updated Registration Data</h2>
      {registrationData ? (
        <div>
          <p>Country: {registrationData.country}</p>
          <p>State: {registrationData.state}</p>
          <p>City: {registrationData.city}</p>
          <p>Name: {registrationData.name}</p>
          <p>Mobile Number: {registrationData.mobileNumber}</p>
          {registrationData.image1URL && <img src={registrationData.image1URL} alt="Image 1" style={{ width: '100px', height: '100px' }} />}
          {registrationData.image2URL && <img src={registrationData.image2URL} alt="Image 2" style={{ width: '100px', height: '100px' }} />}
        </div>
      ) : (
        <p>Loading data...</p>
      )}
    </div>
  );
};

export default NextPage;
