import { onValue, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { database, firestore } from "../firebase-config";
import payimage from "../images/pay.png";
import { collection, getDocs } from "firebase/firestore";
import Headerlogin from "./Header";
import RedirectLink from "./redirectlink";

const Myaccount = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [allSubmissions, setAllSubmissions] = useState([]);
    const [registrationData, setRegistrationData] = useState(null);

    // User Redirect To Payment Page
    const handlePayment = () => {
        navigate("/payment", { state: { id: location.state.id } });
    };

    // User Redirect To Upload Document Page
    const handleUpload = () => {
        navigate("/documentupload", { state: { id: location.state.id } });
    };

    // User Redirect To DataView Page
    const handleView = () => {
        navigate("/viewdetails", { state: { id: location.state.id } });
    };

    // Fetch data from Firebase Realtime Database
    useEffect(() => {
        if (location.state?.id) {
            const registrationRef = ref(database, `registrations/${location.state.id}`);
            onValue(registrationRef, (snapshot) => {
                const data = snapshot.val();
                setRegistrationData(data);
            });
        }
    }, [location.state?.id]);

    // Fetch data from Firestore
    useEffect(() => {
        const fetchFirestoreData = async () => {
            try {
                const querySnapshot = await getDocs(collection(firestore, "formSubmissions"));
                const submissions = [];
                querySnapshot.forEach((doc) => {
                    submissions.push({ id: doc.id, ...doc.data() });
                });
                setAllSubmissions(submissions);
            } catch (error) {
                console.error("Error fetching Firestore data: ", error);
            }
        };

        fetchFirestoreData();
    }, []);

    return (
        <div>
            <Headerlogin />
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <RedirectLink />
                        <div className="form-panel m-0" style={{ paddingTop: 10 }}>
                            <div id="example_wrapper" className="dataTables_wrapper no-footer">
                                <div className="dataTables_length" id="example_length">
                                    <label>
                                        Show entries
                                        <select
                                            name="example_length"
                                            aria-controls="example"
                                            className="form-control form-control-sm"
                                        >
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </label>
                                </div>
                                <div id="example_filter" className="dataTables_filter">
                                    <label>
                                        Search:
                                        <input
                                            type="search"
                                            className="form-control form-control-sm"
                                            placeholder="Search"
                                            aria-controls="example"
                                        />
                                    </label>
                                </div>
                                <div className="table-responsive">
                                    <table
                                        id="example"
                                        className="table table-striped table-bordered dataTable no-footer"
                                        cellSpacing={0}
                                        width="100%"
                                        aria-describedby="example_info"
                                        style={{ width: "100%" }}
                                    >
                                        <thead>
                                            <tr>
                                                <th>S.&nbsp;No.</th>
                                                <th>User&nbsp;Action</th>
                                                <th>Application&nbsp;No</th>
                                                <th>Name&nbsp;of&nbsp;Location</th>
                                                <th>Type&nbsp;of&nbsp;RO</th>
                                                <th>Category</th>
                                                <th>Mode&nbsp;of&nbsp;Selection</th>
                                                <th>Applied&nbsp;Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allSubmissions.length > 0 ? (
                                                allSubmissions.map((submission, index) => (
                                                    <tr key={submission.id}>
                                                        <td>{index + 1}</td>
                                                        <td className="action-user">
                                                            <a id="payonline" onClick={handlePayment}>
                                                                <img
                                                                    src={payimage}
                                                                    style={{
                                                                        width: "100%",
                                                                        maxWidth: 120,
                                                                        marginBottom: 5,
                                                                    }}
                                                                    alt="Pay Now"
                                                                />
                                                            </a>
                                                            <a onClick={handleView} className="btn btn-primary btn-sm" style={{ marginBottom: 5 }}>
                                                                View
                                                            </a>
                                                            <a onClick={handleUpload} className="btn btn-primary btn-sm" style={{ marginBottom: 5 }}>
                                                                Upload Docs
                                                            </a>
                                                            <p style={{ color: "red" }}>
                                                                To complete your application, submit an application fee of Rs 25,000/- before the last date.
                                                            </p>
                                                        </td>
                                                        <td>{registrationData?.ApplicationId || "N/A"}</td>
                                                        <td>{registrationData?.location || "N/A"}</td>
                                                        <td>{submission.rotype || "N/A"}</td>
                                                        <td>{submission.category || "N/A"}</td>
                                                        <td>{submission.Modeselection || "N/A"}</td>
                                                        <td>{registrationData?.date || "N/A"}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="8">No submissions found.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="dataTables_info" id="example_info" role="status" aria-live="polite">
                                    Showing 1 to {allSubmissions.length} of {allSubmissions.length} entries
                                </div>
                                <div className="dataTables_paginate paging_simple_numbers" id="example_paginate">
                                    <a className="paginate_button previous disabled" aria-controls="example" tabIndex={-1} id="example_previous">
                                        Previous
                                    </a>
                                    <span>
                                        <a className="paginate_button current" aria-controls="example" tabIndex={0}>
                                            1
                                        </a>
                                    </span>
                                    <a className="paginate_button next disabled" aria-controls="example" tabIndex={-1} id="example_next">
                                        Next
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Myaccount;
