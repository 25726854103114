import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { database, firestore } from "../firebase-config";
import { onValue, ref } from "firebase/database";
import { collection, getDocs } from "firebase/firestore";
import RedirectLink from "./redirectlink";
import Header from "./Header";


const ViewDetails = () => {

    const location = useLocation();
    const [registrationData, setDatainRegistration] = useState(null);
    const [usersubmission, setusersubmission] = useState([]);
    console.log(usersubmission);

    useEffect(() => {
        if (location.state.id) {
            const registrationRef = ref(database, `registrations/${location.state.id}`);
            onValue(registrationRef, (snapshot) => {
                const data = snapshot.val();
                setDatainRegistration(data);
            })
        }
    }, [location.state.id])

    useEffect(() => {
        const fetchFirestoreData = async () => {
            const querySnapshot = await getDocs(collection(firestore, "formSubmissions"));
            const submission = [];
            querySnapshot.forEach((doc) => {
                submission.push({
                    ...doc.data()
                });
            });
            setusersubmission(submission);
        };
        fetchFirestoreData();
    }, [])

    return (
        <div>
            <Header />

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <RedirectLink />
                        <div className="form-panel m-0">
                            <div className="heading"><i className="fa fa-bars" /> Application Form</div>
                            <div className="box">
                                <div className="row">
                                    <div className="col-md-2">

                                        <p className="m-0"><b>Title</b></p>
                                    </div>
                                    <div className="col-md-4 toppad">
                                        <p className="m-0">
                                            {usersubmission.map((submittion) => (
                                                <span>
                                                    {submittion.title}
                                                </span>
                                            ))}
                                            &nbsp;
                                            {registrationData && (

                                                <span>
                                                    {registrationData.state}
                                                </span>
                                            )}
                                        </p>

                                    </div>
                                    <div className="col-md-2">
                                        <p className="m-0"><b>Location Description</b></p>
                                    </div>
                                    <div className="col-md-4">
                                        {registrationData && (
                                            <p id="selectlocation" className="m-0">{registrationData.location}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <hr />
                                    </div>
                                    <div className="col-md-6">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <p className="m-0"><b>Type of RO</b></p>
                                    </div>
                                    <div className="col-md-4 toppad">
                                        {usersubmission.map((submittion) => (

                                            <p className="m-0" id="typeof">{submittion.rotype}</p>
                                        ))}
                                    </div>
                                    <div className="col-md-2">
                                        <p className="m-0"><b>Mode of Selection</b></p>
                                    </div>
                                    <div className="col-md-4">
                                        {usersubmission.map((submittion) => (

                                            <p className="m-0" id="modeselection">{submittion.Modeselection}</p>
                                        ))}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <hr />
                                    </div>
                                    <div className="col-md-6">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <p className="m-0"><b>Category</b></p>
                                    </div>
                                    <div className="col-md-4 toppad">
                                        {usersubmission.map((submittion) => (
                                            <p id="Category" className="m-0">{submittion.category}</p>
                                        ))}
                                    </div>
                                    <div className="col-md-2">
                                        <p className="m-0"><b>State</b></p>
                                    </div>
                                    <div className="col-md-4">
                                        {registrationData && (
                                            <p className="m-0" id="selectstate2">{registrationData.state}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <hr />
                                    </div>
                                    <div className="col-md-6">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <p className="m-0"><b>Published Date</b></p>
                                    </div>
                                    <div className="col-md-4 toppad">
                                        {usersubmission.map((submittion) => (
                                            <p id="pusblisheddata" className="m-0">{submittion.PublishedDate}</p>
                                        ))}
                                    </div>
                                    <div className="col-md-2">
                                        <p className="m-0"><b>Closing Date</b></p>
                                    </div>
                                    <div className="col-md-4">
                                        {usersubmission.map((submittion) => (
                                            <p id="closingdate" className="m-0">{submittion.ClosingDate}</p>
                                        ))}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <hr />
                                    </div>
                                    <div className="col-md-6">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <p className="m-0"><b>Apply As</b></p>
                                    </div>
                                    <div className="col-md-4 toppad">
                                        {registrationData && (

                                            <span id="applyas">
                                                {registrationData.applyus}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-md-2">
                                        <p className="m-0"><b>Retail Outlet Eligibility Criteria for Land</b></p>
                                    </div>
                                    <div className="col-md-4">
                                        {usersubmission.map((submittion) => (
                                            <p id="Retailoutlet" className="m-0">{submittion.RetailOutlet}</p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-panel m-0">
                            <div className="heading"><i className="fa fa-user" /> Personal Information</div>
                            <div className="box">
                                {registrationData?.firname && (
                                    <div id="getFirm" className="row">
                                        <div className="col-md-2">
                                            <p><b>Firm name *</b></p>
                                        </div>
                                        <div className="col-md-10 toppad">
                                            <span id="firmName">{registrationData.firname}</span>
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-md-2">
                                        <p><b>Type of RO *</b></p>
                                    </div>
                                    {usersubmission.map((submission) => (
                                        <div className="col-md-4 toppad" id="typeof2">
                                            {submission.rotype}
                                        </div>
                                    ))}

                                    <div className="col-md-2">
                                        <p><b>Name of Company *</b></p>
                                    </div>
                                    {registrationData && (
                                        <div className="col-md-4 toppad" id="nameofCompany">
                                            {registrationData.nameofcompany}
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <p><b>First Name *</b></p>
                                    </div>
                                    {registrationData && (
                                        <div className="col-md-4 toppad" id="firstName">
                                            {registrationData.firstname}
                                        </div>
                                    )}
                                    <div className="col-md-2">
                                        <p><b>Last Name *</b></p>
                                    </div>
                                    {registrationData && (
                                        <div className="col-md-4 toppad" id="LastName">
                                            {registrationData.lastname}
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <p><b>Category Name *</b></p>
                                    </div>
                                    {usersubmission.map((submission) => (
                                        <div className="col-md-4 toppad" id="Category2">
                                            {submission.category}
                                        </div>
                                    ))}
                                    <div className="col-md-2">
                                        <p><b>Gender *</b></p>
                                    </div>
                                    {registrationData && (
                                        <div className="col-md-4 toppad" id="gender">
                                            {registrationData.gender}
                                        </div>
                                    )}
                                </div>
                                {registrationData && (
                                    <div>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <p><b>Mobile Number *</b></p>
                                            </div>
                                            <div className="col-md-4 toppad" id="mobileNumber">
                                                {registrationData.mobile}
                                            </div>
                                            <div className="col-md-2">
                                                <p><b>Landline Number </b></p>
                                            </div>
                                            <div className="col-md-4 toppad" id="landlineNumber">
                                                {registrationData.landline}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <p><b>Res. Address *</b></p>
                                            </div>
                                            <div className="col-md-4 toppad" id="resAddress">
                                                {registrationData.resaddress}
                                            </div>
                                            <div className="col-md-2">
                                                <p><b>Pincode </b></p>
                                            </div>
                                            <div className="col-md-4 toppad" id="pincode">
                                                {registrationData.pincode}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <p><b>State *</b></p>
                                            </div>
                                            <div className="col-md-4 toppad" id="userstate">
                                                {registrationData.userstate}
                                            </div>
                                            <div className="col-md-2">
                                                <p><b>District *</b></p>
                                            </div>
                                            <div className="col-md-4 toppad" id="userdistrict">
                                                {registrationData.userdistrict}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <p><b>Email *</b></p>
                                            </div>
                                            <div className="col-md-4 toppad" id="email">
                                                {registrationData.email}
                                            </div>
                                            <div className="col-md-2">
                                                <p><b>PAN Number * </b></p>
                                            </div>
                                            <div className="col-md-4 toppad" id="pannumber">
                                                {registrationData.pannumber}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 ">
                                                <p><b>Whether Applicant is Indian Citizen *</b></p>
                                            </div>
                                            <div className="col-md-2 toppad" id="indianCitizen">
                                                {registrationData.indiancitizen}
                                            </div>
                                            <div className="col-md-4">
                                                <p><b>Residential of Indian as IT Rule * </b></p>
                                            </div>
                                            <div className="col-md-2 toppad" id="itRule">
                                                {registrationData.itrulevalue}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <p><b>Date of Birth *</b></p>
                                            </div>
                                            <div className="col-md-4 toppad" id="dob">
                                                {registrationData.dob}
                                            </div>
                                            <div className="col-md-4">
                                                <p><b>Physical Disability * </b></p>
                                            </div>
                                            <div className="col-md-2 toppad" id="physicalDisability">
                                                {registrationData.physicalvalue}
                                            </div>
                                        </div>
                                        <div className="row flexdiv">
                                            <div className="col-md-3">
                                                <p><b>Father/Husband Name *</b></p>
                                            </div>
                                            <div className="col-md-3 toppad" id="fatehrFName">
                                                {registrationData.fatehrFName}
                                            </div>
                                            <div className="col-md-3 toppad">
                                                {registrationData.fatehrMName}
                                            </div>
                                            <div className="col-md-3 toppad">
                                                {registrationData.fatehrLName}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <p><b>Marital Status *</b>(In case of married women Husband's Name)</p>
                                            </div>
                                            <div className="col-md-9 toppad" id="maritalStatus">
                                                {registrationData.maritalStatus}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {registrationData?.firname && (
                                    <div className="row flexdiv" id="spousediv">
                                        <div className="col-md-3">
                                            <p><b>Spouse Name *</b></p>
                                        </div>
                                        <div className="col-md-3 toppad" id="spouseFName">
                                            {registrationData.spouseFName}
                                        </div>
                                        <div className="col-md-3 toppad">
                                            {registrationData.spouseMName}
                                        </div>
                                        <div className="col-md-3 toppad">
                                            {registrationData.spouseLName}
                                        </div>
                                    </div>
                                )}


                            </div>
                        </div>

                        <div className="form-panel m-0">
                            <div className="heading"><i className="fa fa-cap" /> Applicant's Education Qualification (Minimum 10th
                                pass or Equivalent)</div>
                            <div className="box">
                                {registrationData && (
                                    <div className="row">
                                        <div className="col-md-4 toppad">
                                            <p><b>Qualification *</b></p>
                                            <span id="qualification">{registrationData.qualification}</span>
                                        </div>
                                        <div className="col-md-4 toppad">
                                            <p><b>Board/University/Institute *</b></p>
                                            <span id="board">{registrationData.board}</span>
                                        </div>
                                        <div className="col-md-2 toppad">
                                            <p><b>Degree *</b></p>
                                            <span id="degree">{registrationData.degree}</span>
                                        </div>
                                        <div className="col-md-2 toppad">
                                            <p><b>Year *</b></p>
                                            <span id="year">{registrationData.year}</span>
                                        </div>
                                        <div className="col-md-2 toppad">
                                            <p><b>Group Type *</b></p>
                                            <span id="groupType">{registrationData.grouptype}</span>
                                        </div>
                                    </div>
                                )}
                                <div className="row hidegroup3 hide">
                                    {registrationData?.leaseholder && (
                                        <div className="col-md-3 toppad">
                                            <p><b>Name(s) of the owner of Land/Leaseholder *</b></p>
                                            <span id="leaseholder">{registrationData.leaseholder}</span>
                                        </div>
                                    )}
                                    {registrationData?.relation && (
                                        <div className="col-md-3 toppad">
                                            <p><b>Relationship with Application * </b></p>
                                            <span id="relationshipwithapplication">{registrationData.relation}</span>
                                        </div>
                                    )}
                                    {registrationData?.dateregistration && (
                                        <div className="col-md-3 toppad">
                                            <p><b>Date of registration sale deed / Gift deed / lease deed / date of mutation
                                                *</b></p>
                                            <span id="dateofregistrationsaledeed">{registrationData.dateregistration}</span>
                                        </div>
                                    )}
                                    {registrationData?.khasrano && (
                                        <div className="col-md-3 toppad">
                                            <p><b>Khasra No / Khatouni / Gut No / Survey No. *</b></p>
                                            <span id="khasrano">{registrationData.khasrano}</span>
                                        </div>
                                    )}
                                    {registrationData?.locationofthelan && (
                                        <div className="col-md-12">
                                            <p><b>Location of the Land *</b></p>
                                            <span id="locationoftheland">{registrationData.locationofthelan}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="row hidegroup3 hide">
                                    <div className="col-md-12">
                                        <p><b>Dimension of Land *</b></p>
                                    </div>
                                    {registrationData?.frontage && (
                                        <div className="col-md-3 toppad">
                                            <p><b>Frontage in Meter *</b></p>
                                            <span id="frontage">{registrationData.frontage}</span>
                                        </div>
                                    )}
                                    {registrationData?.depth && (
                                        <div className="col-md-3 toppad">
                                            <p><b>Depth in Meter *</b></p>
                                            <span id="depth">{registrationData.depth}</span>
                                        </div>
                                    )}
                                    {registrationData?.area && (
                                        <div className="col-md-3 toppad">
                                            <p><b>Area (Sq.M) *</b></p>
                                            <span id="area">{registrationData.area}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-12">
                                        <p><b>Bidding Amount *</b></p>
                                    </div>
                                    <div className="col-6 col-md-3">
                                    </div>
                                </div>
                                {registrationData?.dealershipchayan && (
                                    <div className="row toppad">
                                        <div className="col-md-7">
                                            <p><b>are you efilling to transfer the land on sale / long lease of the HPCL Petrol Pump
                                                *</b></p>
                                        </div>
                                        <div className="col-md-2">
                                            <span id="dealershipchayan">{registrationData.dealershipchayan}</span>
                                        </div>
                                    </div>
                                )}
                                {registrationData?.specifyrate && (
                                    <div className="row toppad">
                                        <div className="col-md-4">
                                            <p><b>Please specify rate(RS) term *</b></p>
                                        </div>
                                        <div className="col-md-5">
                                            <span id="specifyrate">{registrationData.specifyrate}</span>
                                        </div>
                                    </div>
                                )}
                                {registrationData?.landunder && (
                                    <div className="row">
                                        <div className="col-md-4">
                                            <p><b>Do you want to offer another plot of land under? *</b></p>
                                        </div>
                                        <div className="col-md-2">
                                            <span id="landunder">{registrationData.landunder}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {registrationData && (
                            <div className="form-panel m-0">
                                <div className="box">
                                    <div className="row">
                                        <div className="col-md-4 toppad">
                                            <p><b>Photo</b></p>
                                            <img id="image1" src={registrationData.image1URL} />
                                        </div>
                                        <div className="col-md-4 toppad">
                                            <p><b>ID Proof</b></p>
                                            <img id="image2" src={registrationData.image2URL} />
                                        </div>
                                        <div className="col-md-4">
                                            <p><b>Address Proof</b></p>
                                            <img id="image3" src={registrationData.image3URL} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewDetails;