import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Headerlogin from "./Headerlogin";
import { getDatabase, ref, get, child } from "firebase/database"; // Firebase Realtime Database

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userCaptchaInput, setUserCaptchaInput] = useState("");
    const [captchaNum1, setCaptchaNum1] = useState(0); // First captcha number
    const [captchaNum2, setCaptchaNum2] = useState(0); // Second captcha number
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("danger"); // danger or success
    const navigate = useNavigate();

    // Generate random numbers for captcha when the component loads
    useEffect(() => {
        generateCaptcha();
    }, []);

    // Function to generate two random numbers for captcha
    const generateCaptcha = () => {
        const num1 = Math.floor(Math.random() * 10); // First random number (0-9)
        const num2 = Math.floor(Math.random() * 10); // Second random number (0-9)
        setCaptchaNum1(num1);
        setCaptchaNum2(num2);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAlertMessage(""); // Reset the alert message

        // Validate captcha: check if user input equals the sum of captchaNum1 and captchaNum2
        if (parseInt(userCaptchaInput) !== captchaNum1 + captchaNum2) {
            setAlertType("danger");
            setAlertMessage("Captcha incorrect. Please try again.");
            return;
        }

        const db = getDatabase();
        const dbRef = ref(db, 'registrations');

        try {
            const snapshot = await get(child(dbRef, '/'));
            if (snapshot.exists()) {
                const registrations = snapshot.val();

                // Find user with matching email and password
                const [uniqueID] = Object.entries(registrations).find(
                    ([id, registration]) =>
                        registration.email === email && registration.password === password
                ) || [];

                if (uniqueID) {
                    // Success: Email, password, and captcha match
                    setAlertType("success");
                    setAlertMessage("Login successful! Redirecting...");
                    // Redirect user to their dashboard or account page
                    setTimeout(() => {
                        navigate("/myaccount", { state: { id: uniqueID } });
                    }, 1000);
                } else {
                    // Failure: Email or password do not match
                    setAlertType("danger");
                    setAlertMessage("Invalid email or password.");
                }
            } else {
                setAlertMessage("No user data available.");
            }
        } catch (error) {
            console.error(error);
            setAlertMessage("Error logging in. Please try again later.");
        }
    };

    return (
        <div>
            <Headerlogin />
            <div className="container">
                <div className="row">
                    <div className="col-md-6 offset-3">
                        <div className="panel">
                            <p style={{ color: "#000" }}>
                                Don't have an account?{" "}
                                <a href="/register" style={{ color: "blue", fontWeight: "600", cursor: "pointer" }}>
                                    Register Now
                                </a>
                            </p>
                            <h3>Applicant Login Panel</h3>
                            <form id="loginForm" onSubmit={handleSubmit}>
                                {/* Alert Message */}
                                {alertMessage && (
                                    <div className={`alert alert-${alertType} alert-dismissible fade show`} role="alert">
                                        <span>{alertMessage}</span>
                                        <button type="button" className="close" onClick={() => setAlertMessage("")}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                )}

                                <div className="row">
                                    <div className="col-md-12">
                                        <i className="form-icon fa fa-envelope" />
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-control p-35"
                                            placeholder="Email ID"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <i className="form-icon fa fa-lock" />
                                        <input
                                            type="password"
                                            name="password"
                                            className="form-control p-35"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </div>

                                    {/* Captcha */}
                                    <div className="col-md-12 mt-3">
                                        <div className="captcha-container">
                                            <span
                                                style={{
                                                    padding: "5px 27px",
                                                    border: "1px solid #ddd",
                                                    background: "radial-gradient(circle, rgba(255,255,255,1) 14%, rgba(85,172,254,1) 100%)",
                                                    display: "inline-block",
                                                    color: "#000",
                                                    fontSize: "16px",
                                                    marginRight: "10px",
                                                }}
                                            >
                                                {captchaNum1} + {captchaNum2}
                                            </span>
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={generateCaptcha}
                                            >
                                                Refresh Captcha
                                            </button>
                                        </div>
                                        <input
                                            type="text"
                                            name="captchaInput"
                                            className="form-control p-35 mt-2"
                                            placeholder="Enter the sum"
                                            value={userCaptchaInput}
                                            onChange={(e) => setUserCaptchaInput(e.target.value)}
                                            required
                                        />
                                    </div>

                                    <div className="col-6 col-sm-6">
                                        <button type="submit" className="btn btn-primary mt-20">
                                            Submit
                                        </button>
                                    </div>
                                    <div className="col-6 col-sm-6 text-right">
                                        <a href="/forgotpassword" style={{ marginTop: "10px", display: "block" }}>
                                            <b>Forgot Password?</b>
                                        </a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
