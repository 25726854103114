import React, { useState, useEffect } from "react";
import RedirectLink from "./redirectlink";
import Header from "./Header";

import { database } from "../firebase-config";
import { ref as databaseRef, onValue } from "firebase/database";
import { useLocation } from "react-router-dom";
const Invoice = () => {
    const location = useLocation();
    const [documents, setDocuments] = useState([]);
    console.log(documents);
    useEffect(() => {
        if (location.state && location.state.id) {
            const docRef = databaseRef(database, `registrations/${location.state.id}/userpaymentsshow`);
            onValue(docRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    const formattedData = Object.keys(data).map((key, index) => ({
                        id: key,
                        sNo: index + 1,
                        ...data[key],
                    }));
                    setDocuments(formattedData);
                } else {
                    setDocuments([]); // Clear the list if no documents are found
                }
            });
        }
    }, [location.state.id]);
    const handleView = (fileUrl) => {
        window.open(fileUrl, "_blank");
    };

    return (
        <div>
            <Header />

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <RedirectLink />
                        <div className="form-panel m-0" style={{ "padding-top": "10px" }}>
                            <div id="example_wrapper" className="dataTables_wrapper no-footer"><div className="dataTables_length" id="example_length"><label>Show <select name="example_length" aria-controls="example" className><option value={10}>10</option><option value={25}>25</option><option value={50}>50</option><option value={100}>100</option></select> entries</label></div><div id="example_filter" className="dataTables_filter"><label>Search:<input type="search" className placeholder="Search" aria-controls="example" /></label></div><table id="example" className="table table-striped table-bordered dataTable no-footer" cellSpacing={0} width="100%" aria-describedby="example_info" style={{ "width": "100%" }}>
                                <thead>
                                    <tr><th className="sorting sorting_asc" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="S. No.: activate to sort column descending" style={{ "width": "21.4px" }}>S. No.</th><th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Order ID: activate to sort column ascending" style={{ "width": "34.4px" }}>Order ID</th><th width="20%" className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Purpose of Payment: activate to sort column ascending" style={{ "width": "54.4px" }}>Purpose of Payment</th><th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Payment Date: activate to sort column ascending" style={{ "width": "54.4px" }}>Payment Date</th><th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Payment Mode: activate to sort column ascending" style={{ "width": "54.4px" }}>Payment Mode</th><th className="text-center sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Amount: activate to sort column ascending" style={{ "width": "49.4px" }}>Amount</th><th className="text-center sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Invoice: activate to sort column ascending" style={{ "width": "44.4px" }}>Invoice</th></tr>
                                </thead>
                                <tbody>

                                    {documents.map((doc, index) => (
                                        <tr key={doc.id}>
                                            <td>{doc.sNo}</td>
                                            <td>{doc.orderid}</td>
                                            <td>{doc.purpose}</td>
                                            <td>{doc.paymentdate}</td>
                                            <td>{doc.paymentmode}</td>
                                            <td>{doc.amount}</td>
                                            <td>
                                                <button onClick={() => handleView(doc.fileUrl)} style={{ margin: '5px' }} className="btn btn-primary">View</button>
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            <div style={{ "text-align": "center" }}>
                                        {documents.length === 0 && <p>No Payment yet.</p>}
                            </div>
                            <div className="dataTables_info" id="example_info" role="status" aria-live="polite">Showing 0 to 0 of 0 entries</div><div className="dataTables_paginate paging_simple_numbers" id="example_paginate"><a className="paginate_button previous disabled" aria-controls="example" data-dt-idx={0} tabIndex={-1} id="example_previous">Previous</a><span /><a className="paginate_button next disabled" aria-controls="example" data-dt-idx={1} tabIndex={-1} id="example_next">Next</a></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Invoice;