import React, { useEffect, useState } from "react";
import RedirectLink from "./redirectlink";
import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";
import { collection, doc, getDoc } from "firebase/firestore";
import { database, firestore } from "../firebase-config";
import { onValue, ref, update, push, child, serverTimestamp } from "firebase/database";

const Payment = () => {
  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Define User Id which is passed in the parameter
  const location = useLocation();
  const navigate = useNavigate();
  const [firestoreUser, setFirestoreUser] = useState([]);
  const [registionData, setUserRegistrationData] = useState(null);
  const [paydate] = useState(formatDate(new Date()));
  const [imageUrl, setImageUrl] = useState("");
  const documentId = "uniqueDocId";
  const Paymentstatus = "Under Process";
  console.log(registionData?.firstname + registionData?.lastname);

  const [formData, setFormData] = useState({
    amount: "",
    transactionID: "",
  });

  useEffect(() => {
    const fetchFirestoreData = async () => {
      try {
        const docRef = doc(firestore, "qrcode", documentId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setImageUrl(data.imageUrl || ""); // Update with the image URL from Firestore
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching Firestore data: ", error);
      }
    };

    fetchFirestoreData();
  }, [documentId]);

  useEffect(() => {
    if (location.state?.id) {
      const registrationRef = ref(database, `registrations/${location.state.id}`);
      onValue(registrationRef, (snapshot) => {
        const data = snapshot.val();
        setUserRegistrationData(data);
      });
    }
  }, [location.state?.id]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const uniqueId = push(child(ref(database), `registrations/${location.state.id}/userpayments`)).key;
    
    const updates = {};
    updates[`registrations/${location.state.id}/userpayments/${uniqueId}`] = {
      amount: formData.amount,
      transactionID: formData.transactionID,
      paymentdate: paydate,
      Paymentstatus: Paymentstatus,
      username: registionData?.firstname + " " + registionData?.lastname,
      email: registionData?.email,
      timestamp: serverTimestamp()
    };
    
    await update(ref(database), updates);
    navigate("/paymentreceipt", { state: { id: location.state.id } });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Header />

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <RedirectLink />
          </div>
          <div className="col-md-12">
            <div className="form-panel" style={{ background: "#fff" }}>
              <div className="heading">
                <i className="fa fa-inr" /> Secure Order Form
              </div>
              <div className="box">
                <div className="row">
                  <div className="col-md-12">
                    <p className="m-0 text-center">
                      Payment Options for:{" "}
                      <span style={{ color: "blue" }}>Application No.</span>{" "}
                      {registionData && (
                        <span>{registionData.ApplicationId}</span>
                      )}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p className="m-0 payheader">Pay Now with</p>
                    {imageUrl ? (
                      <img id="uploadedImage" src={imageUrl} alt="QR Code" />
                    ) : (
                      <p>No image available.</p>
                    )}
                    <p>
                      <b>Step 1:</b> Pay by scanning the UPI QR Code
                      <br />
                      <b>Step 2:</b> After payment, enter the transaction id in
                      the order summary
                      <br />
                      <b>Step 3:</b> Click on the submit button
                    </p>
                  </div>
                  <div className="col-md-6">
                    <form id="paymentForm" onSubmit={handleSubmit}>
                      <div
                        style={{
                          border: "1px solid #333",
                          padding: "10px",
                          margin: "20px 5px 10px 5px",
                        }}
                      >
                        <p className="text-center">Order Summary</p>
                        <input type="hidden" id="dateInput" name="paydateInput" />
                        <div
                          className="alert alert-danger d-none"
                          role="alert"
                          id="error-message"
                        >
                          Enter the correct transaction ID
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <p>
                              <b>Name of Location: </b>
                            </p>
                          </div>
                          <div className="col-md-8">
                            {registionData && (
                              <p>{registionData.location}</p>
                            )}
                          </div>
                          <div className="col-md-4">
                            <label className="control-label">
                              Paying To <span className="red-btn">*</span>
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="text"
                              name="websiteName"
                              className="form-control"
                              defaultValue="www.hindustanpetroleumcoporation.in"
                              readOnly
                              required
                            />
                          </div>
                          <div className="col-md-4">
                            <label className="control-label">
                              Amount <span className="red-btn">*</span>
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="number"
                              name="amount"
                              value={formData.amount}
                              onChange={handleChange}
                              className="form-control"
                              required
                            />
                          </div>
                          <div className="col-md-4">
                            <label className="control-label">
                              Taxes <span className="red-btn">*</span>
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="number"
                              name="taxes"
                              defaultValue={0}
                              readOnly
                              className="form-control"
                              required
                            />
                          </div>
                          <div className="col-md-4">
                            <label className="control-label">
                              Transaction ID <span className="red-btn">*</span>
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              type="text"
                              id="transactionId"
                              value={formData.transactionID}
                              onChange={handleChange}
                              name="transactionID"
                              className="form-control"
                              required
                            />
                          </div>
                          <div className="col-sm-4" />
                          <div className="col-sm-8">
                            <button
                              type="submit"
                              className="btn btn-primary mt-20"
                            >
                              Pay Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
