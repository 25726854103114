import React from "react";
import RedirectLink from "./redirectlink";
import Header from "./Header";

const SecurityPage = () => {
    return (
        <div>
            <Header />

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <RedirectLink />
                        <div className="form-panel m-0" style={{ paddingTop: '10px' }}>
                            <div id="example_wrapper" className="dataTables_wrapper no-footer"><div className="dataTables_length" id="example_length"><label>Show <select name="example_length" aria-controls="example" className><option value={10}>10</option><option value={25}>25</option><option value={50}>50</option><option value={100}>100</option></select> entries</label></div><div id="example_filter" className="dataTables_filter"><label>Search:<input type="search" className placeholder="Search" aria-controls="example" /></label></div><table id="example" className="table table-striped table-bordered dataTable no-footer" cellSpacing={0} width="100%" aria-describedby="example_info" style={{ width: '100%' }}>
                                <thead>
                                    <tr><th className="text-center sorting sorting_asc" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="S. No.: activate to sort column descending" style={{ width: '44.4px' }}>S. No.</th><th width="20%" className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Name of Location: activate to sort column ascending" style={{ width: '58.4px' }}>Name of Location</th><th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Date: activate to sort column ascending" style={{ width: '34.4px' }}>Date</th><th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Security Status: activate to sort column ascending" style={{ width: '108.4px' }}>Security Status</th><th className="text-center sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} aria-label="Status: activate to sort column ascending" style={{ width: '47.4px' }}>Status</th></tr>
                                </thead>
                                <tbody>
                                    <tr className="odd"><td valign="top" colSpan={5} className="dataTables_empty">No data available in table</td></tr></tbody>
                            </table><div className="dataTables_info" id="example_info" role="status" aria-live="polite">Showing 0 to 0 of 0 entries</div><div className="dataTables_paginate paging_simple_numbers" id="example_paginate"><a className="paginate_button previous disabled" aria-controls="example" data-dt-idx={0} tabIndex={-1} id="example_previous">Previous</a><span /><a className="paginate_button next disabled" aria-controls="example" data-dt-idx={1} tabIndex={-1} id="example_next">Next</a></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SecurityPage;