import React from "react";
import Headerlogin from "./Header";
import RedirectLink from "./redirectlink";
const Latefine = () => {
    return (
        <div>
            <Headerlogin />
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <RedirectLink />
                        <div className="form-panel m-0">
                            <div id="example_wrapper" className="dataTables_wrapper no-footer">

                                <p style={{ "textAlign": "center", "padding": "10px 0" }}>No Late Fine Yet</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Latefine;