// Loader.js
import React from 'react';
import './Loader.css'
const Loader = () => {
    return (
        <div className="loader-overlay">
            <div className="loader-icon">
                {/* You can use any loader icon or spinner */}
                <div className="spinner"></div>
            </div>
        </div>
    );
};

export default Loader;
