import React from "react";

const Footer = () => {
    return (
        <div>
            <footer>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-6">
                            <p className="ff">Copyright © hindustanpetroleumcoporation.in<br />All rights reserved</p>
                        </div>
                        <div className="col-12 col-md-6">
                            <p className="text-right ff">Contact us at email:<br />support@hindustanpetroleumcoporation.in</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
export default Footer;