import React, { useEffect, useState } from "react";
import { ref, onValue, update, get } from "firebase/database"; // Use 'update' instead of 'set' to update data
import { useLocation, useNavigate } from "react-router-dom"; // import useNavigate
import { database, storage, firestore } from '../firebase-config'; // Import Firebase storage
import { getDownloadURL, uploadBytes, ref as storageRef } from "firebase/storage"; // Import storage functions
import { collection, getDocs } from "firebase/firestore";
import '../css/alert.css'
import Headerlogin from "./Headerlogin";
import Loader from "./Loader";

const Formsubmit = () => {

    const formatDate = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, "0");
        const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const year = d.getFullYear();
        return `${day}/${month}/${year}`;
    };
    const generateUniqueID = () => {
        const uniqueNumber = Date.now(); // Generates a unique timestamp
        return `AS2024-GOVT-${uniqueNumber}`;
    };
    const location = useLocation();
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false); // State to show/hide custom alert
    const [alertMessage, setAlertMessage] = useState('');
    const [allSubmissions, setAllSubmissions] = useState([]);
    const [registrationData, setRegistrationData] = useState(null);
    const [selectedOption, setSelectedOption] = useState("Individual");
    const [maritalStatus, setMaritalStatus] = useState("");
    const [indiancityxzenvalue, setindiancityzen] = useState("Yes");
    const [itrulevalue, setitrulchange] = useState("Yes");
    const [physicalvalue, setphysicalstatus] = useState("");
    const [hiddenInputValue, setHiddenInputValue] = useState(formatDate(new Date()));
    const [loading, setLoading] = useState(false);
    const [landerstatusvalue, setlandurstatus] = useState("");
    const [pincode, setPincode] = useState('');
    console.log(itrulevalue);
    const [formData, setFormData] = useState({
        firname: "",
        uniqueID: generateUniqueID(),
        nameofcompany: "",
        firstname: "",
        lastname: "",
        gender: "",
        mobile: "",
        landline: "",
        resaddress: "",
        userstate: "",
        userdistrict: "",
        email: "",
        pannumber: "",
        indiancitizen: "",
        dob: "",
        fatehrFName: "",
        fatehrMName: "",
        fatehrLName: "",
        spouseFName: "",
        spouseMName: "",
        spouseLName: "",
        qualification: "",
        board: "",
        degree: "",
        year: "",
        leaseholder: "",
        relation: "",
        dateregistration: "",
        khasrano: "",
        locationoftheland: "",
        frontage: "",
        depth: "",
        area: "",
        dealershipchayan: "",
        password: "",
        specifyrate: ""


    });
    const [selectedGroup, setSelectedGroup] = useState('');

    const [image1, setImage1] = useState(null); // State to hold image 1
    const [image2, setImage2] = useState(null); // State to hold image 2
    const [image3, setImage3] = useState(null); // State to hold image 2

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleCityzen = (event) => {
        setindiancityzen(event.target.value);

    }



    const handlephysicalstatus = (event) => {
        setphysicalstatus(event.target.value);
    }
    const handleKeyDown = (event) => {
        if (event.keyCode === 69) {
            event.preventDefault(); // Prevent the default action for 'E' key
        }
    };
    const handlepincodeChange = (event) => {
        const value = event.target.value;

        // Allow only numeric values and limit the length
        if (/^\d{0,6}$/.test(value)) {
            setPincode(value);
        }
    };

    const handlelandunderstatus = (event) => {
        setlandurstatus(event.target.value);
    }
    const handleitrulechange = (event) => {
        setitrulchange(event.target.value)
    }

    const handleMaritalStatusChange = (event) => {
        setMaritalStatus(event.target.value);
    };


    const handleGroupStatusChange = (event) => {
        setSelectedGroup(event.target.value);
        console.log(event.target.value);
    };

    console.log(registrationData)
    useEffect(() => {
        if (location.state?.id) {
            const registrationsRef = ref(database, `registrations/${location.state.id}`);
            onValue(registrationsRef, (snapshot) => {
                const data = snapshot.val();
                setRegistrationData(data);

            })
        } else {
            console.log("hi")
        }
    }, [location.state?.id])



    useEffect(() => {
        const fetchFirestoreData = async () => {
            const querySnapshot = await getDocs(collection(firestore, "formSubmissions"));
            const submissions = [];
            querySnapshot.forEach((doc) => {
                submissions.push({
                    ClosingDate: doc.data().ClosingDate,
                    rotype: doc.data().rotype,
                    Modeselection: doc.data().Modeselection,
                    PublishedDate: doc.data().PublishedDate,
                    RetailOutlet: doc.data().RetailOutlet,
                    category: doc.data().category,
                    title: doc.data().title

                });
            });
            setAllSubmissions(submissions);
        }
        fetchFirestoreData();
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        let image1URL = null;
        let image2URL = null;
        let image3URL = null;

        // Check if email already exists in the database
        const emailQuery = ref(database, `registrations`);
        const snapshot = await get(emailQuery);

        let emailExists = false;

        snapshot.forEach((childSnapshot) => {
            const childData = childSnapshot.val();
            if (childData.email === formData.email) {
                emailExists = true;
            }
        });

        // If email exists, show an alert and stop the form submission
        if (emailExists) {
            setAlertMessage("Email already exists. Please use a different email.");
            setShowAlert(true);
            setLoading(false);
            return;
        }

        // If email does not exist, proceed with the form submission
        if (image1) {
            const image1Ref = storageRef(storage, `images/${location.state.id}_image1`);
            await uploadBytes(image1Ref, image1);
            image1URL = await getDownloadURL(image1Ref);
        }

        if (image2) {
            const image2Ref = storageRef(storage, `images/${location.state.id}_image2`);
            await uploadBytes(image2Ref, image2);
            image2URL = await getDownloadURL(image2Ref);
        }

        if (image3) {
            const image3Ref = storageRef(storage, `images/${location.state.id}_image3`);
            await uploadBytes(image3Ref, image3);
            image3URL = await getDownloadURL(image3Ref);
        }

        const registrationRef = ref(database, `registrations/${location.state.id}`);
        await update(registrationRef, {
            firname: formData.firname,
            grouptype: selectedGroup,
            nameofcompany: formData.nameofcompany,
            firstname: formData.firstname,
            lastname: formData.lastname,
            gender: formData.gender,
            landline: formData.landline,
            resaddress: formData.resaddress,
            pincode: pincode,
            userstate: formData.userstate,
            userdistrict: formData.userdistrict,
            email: formData.email,
            pannumber: formData.pannumber,
            dob: formData.dob,
            date: hiddenInputValue,
            specifyrate: formData.specifyrate,
            mobile: formData.mobile,
            ApplicationId: formData.uniqueID,
            physicalvalue: physicalvalue,
            fatehrFName: formData.fatehrFName,
            fatehrMName: formData.fatehrMName,
            fatehrLName: formData.fatehrLName,
            spouseFName: formData.spouseFName,
            spouseMName: formData.spouseMName,
            spouseLName: formData.spouseLName,
            qualification: formData.qualification,
            board: formData.board,
            degree: formData.degree,
            year: formData.year,
            leaseholder: formData.leaseholder,
            relation: formData.relation,
            dateregistration: formData.dateregistration,
            khasrano: formData.khasrano,
            locationoftheland: formData.locationoftheland,
            frontage: formData.frontage,
            depth: formData.depth,
            area: formData.area,
            password: formData.password,
            landerstatusvalue: landerstatusvalue,
            dealershipchayan: formData.dealershipchayan,
            indiancitizen: indiancityxzenvalue,
            maritalStatus: maritalStatus,
            itrulevalue: itrulevalue,
            applyus: selectedOption,
            image1URL,
            image2URL,
            image3URL,
        });


        navigate("/myaccount", { state: { id: location.state.id } });
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const closeAlert = () => {
        setShowAlert(false);
    };

    const handleImageChange = (e) => {
        if (e.target.name === "image1") {
            setImage1(e.target.files[0]);
        } else if (e.target.name === "image2") {
            setImage2(e.target.files[0]);
        } else if (e.target.name === "image3") {
            setImage3(e.target.files[0]);
        }
    };

    return (

        <div>
            <Headerlogin/>
            <div className="container">
                {showAlert && (
                    <div className="custom-alert-overlay">
                        <div className="custom-alert">
                            <p>{alertMessage}</p>
                            <button onClick={closeAlert}>OK</button>
                        </div>
                    </div>
                )}
                <div className="row">
                    {loading && <Loader />}
                    <div className="col-md-12">
                        <form onSubmit={handleSubmit} enctype="multipart/form-data">
                            <input type="hidden" id="hiddenInput" name="hiddenInput" />
                            <input
                                type="hidden"
                                name="hiddenField"
                                value={hiddenInputValue}
                                readOnly
                            />
                            <input
                                type="hidden"
                                name="uniqueID"
                                value={formData.uniqueID}
                                readOnly
                            />

                            <div className="form-panel">
                                <div className="heading"><i className="fa fa-bars"></i> Application Form</div>
                                <div className="box">
                                    <div className="row">
                                        <div className="col-6 col-md-2">
                                            <p className="m-0"><b>Title</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">

                                            <p className="m-0">{allSubmissions.map((submission) => (
                                                <span>{submission.title}</span>))}
                                                {registrationData && (
                                                    <span className="submit-state"> {registrationData.state}
                                                    </span>)} </p>
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <p className="m-0"><b>Location Description</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            {registrationData && (
                                                <p id="submit-location" className="m-0">{registrationData.location}</p>)}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-6">
                                            <hr />
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-2">
                                            <p className="m-0"><b>Type of RO</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            {allSubmissions.map((submission) => (
                                                <p className="m-0">{submission.rotype}</p>))}
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <p className="m-0"><b>Mode of Selection</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            {allSubmissions.map((submission) => (
                                                <p className="m-0">{submission.Modeselection}</p>))}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-6">
                                            <hr />
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-2">
                                            <p className="m-0"><b>Category</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            {allSubmissions.map((submission) => (
                                                <p className="m-0">{submission.category}</p>))}
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <p className="m-0"><b>State</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            {registrationData && (
                                                <p className="m-0 submit-state">{registrationData.state}</p>)}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-6">
                                            <hr />
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-2">
                                            <p className="m-0"><b>Published Date</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            {allSubmissions.map((submission) => (
                                                <p className="m-0">{submission.PublishedDate}</p>))}
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <p className="m-0"><b>Closing Date</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            {allSubmissions.map((submission) => (
                                                <p className="m-0">{submission.ClosingDate}</p>))}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-6">
                                            <hr />
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-2">
                                            <p className="m-0"><b>Apply As</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <input type="radio" className="applyas" name="applyas" value="Individual"
                                                checked={selectedOption === "Individual"} onChange={handleOptionChange} /> Individual&nbsp;&nbsp;&nbsp;
                                            <input type="radio" className="applyas" name="applyas" value="Partnership" checked={selectedOption === "Partnership"} onChange={handleOptionChange} />
                                            Partnership
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <p className="m-0"><b>Retail Outlet Eligibility Criteria for Land</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            {allSubmissions.map((submission) => (
                                                <p className="m-0">{submission.RetailOutlet}</p>))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-panel m-0">
                                <div className="heading"><i className="fa fa-user"></i> Personal Information</div>
                                <div className="box">
                                    {selectedOption === "Partnership" && (
                                        <div className="firmname" >
                                            <div className="row">
                                                <div className="col-6 col-md-2">
                                                    <p><b>Firm name *</b></p>
                                                </div>
                                                <div className="col-6 col-md-10">
                                                    <input type="text" name="firname" className="form-control rg"
                                                        placeholder="Firm Name" onChange={handleChange} value={formData.firname} required={selectedOption === "Partnership"} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-6 col-md-2">
                                            <p><b>Type of RO *</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <input type="text" name="typeOfRo" className="form-control" value="Regular"
                                                readonly="" required="" />
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <p><b>Name of Company *</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <select name="nameofcompany" onChange={handleChange} value={formData.nameofcompany} className="form-control" required>
                                                <option value="">Select Company</option>
                                                <option value="Hindustan Petroleum (HPCL)">Hindustan Petroleum (HPCL)
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-2">
                                            <p><b>First Name *</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <input type="text" id="fname" name="firstname" value={formData.firstname} onChange={handleChange} className="form-control"
                                                placeholder="First Name" required />
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <p><b>Last Name *</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <input type="text" id="lname" name="lastname" onChange={handleChange} value={formData.lastname} className="form-control"
                                                placeholder="Last Name" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-2">
                                            <p><b>Category Name *</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <input type="text" name="categoryName" className="form-control" value="Open"
                                                readonly="" />
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <p><b>Gender *</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <select name="gender" className="form-control" value={formData.gender} onChange={handleChange}>
                                                <option value="">Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-2">
                                            <p><b>Mobile Number *</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <input type="number"
                                                onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                pattern="[6789][0-9]{9}"
                                                onkeypress="if(this.value.length==10) return false;" maxlength="10"
                                                name="mobile" className="form-control" value={formData.mobile} onChange={handleChange} />
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <p><b>Landline Number </b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <input type="number"
                                                onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                                name="landline" value={formData.landline} onChange={handleChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-2">
                                            <p><b>Res. Address *</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <input type="text" value={formData.resaddress} onChange={handleChange} name="resaddress" className="form-control" />
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <p><b>Pincode </b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <input
                                                type="text"
                                                value={pincode}
                                                onChange={handlepincodeChange}
                                                onKeyDown={handleKeyDown}
                                                name="pincode"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-2">
                                            <p><b>State *</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <select id="state" name="userstate" value={formData.userstate} onChange={handleChange} className="form-control">
                                                <option value="">Select</option>
                                                <option value="Andaman Nicobar">Andaman &amp; Nicobar</option>
                                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                <option value="Assam">Assam</option>
                                                <option value="Bihar">Bihar</option>
                                                <option value="Chandigarh">Chandigarh</option>
                                                <option value="Chhattisgarh">Chhattisgarh</option>
                                                <option value="Dadra Nagar Haveli">Dadra &amp; Nagar Haveli</option>
                                                <option value="Daman Diu">Daman &amp; Diu</option>
                                                <option value="Delhi">Delhi</option>
                                                <option value="Goa">Goa</option>
                                                <option value="Gujarat">Gujarat</option>
                                                <option value="Haryana">Haryana</option>
                                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                <option value="Jammu Kashmir">Jammu &amp; Kashmir</option>
                                                <option value="Jharkhand">Jharkhand</option>
                                                <option value="Karnataka">Karnataka</option>
                                                <option value="Kerala">Kerala</option>
                                                <option value="Ladakh">Ladakh</option>
                                                <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                <option value="Maharashtra">Maharashtra</option>
                                                <option value="Manipur">Manipur</option>
                                                <option value="Meghalaya">Meghalaya</option>
                                                <option value="Mizoram">Mizoram</option>
                                                <option value="Nagaland">Nagaland</option>
                                                <option value="Odisha">Odisha</option>
                                                <option value="Pondicherry">Pondicherry</option>
                                                <option value="Punjab">Punjab</option>
                                                <option value="Rajasthan">Rajasthan</option>
                                                <option value="Sikkim">Sikkim</option>
                                                <option value="Tamil Nadu">Tamil Nadu</option>
                                                <option value="Telangana">Telangana</option>
                                                <option value="Tripura">Tripura</option>
                                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                <option value="Uttarakhand">Uttarakhand</option>
                                                <option value="West Bengal">West Bengal</option>
                                            </select>
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <p><b>District *</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <input type="text" name="userdistrict" value={formData.userdistrict} onChange={handleChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-2">
                                            <p><b>Email *</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <input type="email" value={formData.email} onChange={handleChange} name="email" className="form-control" />
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <p><b>PAN Number * </b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <input etyle="text-transform: uppercase;" type="text"
                                                pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}" name="pannumber" value={formData.pannumber} onChange={handleChange} maxlength="10"
                                                className="form-control" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-4">
                                            <p><b>Whether Applicant is Indian Citizen *</b></p>
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <input type="radio" name="indiancitizen" value="Yes" onChange={handleCityzen} checked={indiancityxzenvalue === "Yes"} />
                                            Yes&nbsp;&nbsp;&nbsp;<input type="radio" name="indiancitizen" value="No" onChange={handleCityzen} checked={indiancityxzenvalue === "No"} /> No
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <p><b>Residential of Indian as IT Rule * </b></p>
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <input type="radio" name="itRule" value="Yes" onChange={handleitrulechange} checked={itrulevalue === "Yes"} />
                                            Yes&nbsp;&nbsp;&nbsp;<input type="radio" onChange={handleitrulechange} name="itRule" checked={itrulevalue === "No"} value="No" /> No
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-2">
                                            <p><b>Date of Birth *</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <input type="date" name="dob" className="form-control" value={formData.dob} onChange={handleChange} />
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <p><b>Physical Disability * </b></p>
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <input type="radio" name="physicalDisability" value="Yes" onChange={handlephysicalstatus} />
                                            Yes&nbsp;&nbsp;&nbsp;<input type="radio" name="physicalDisability" onChange={handlephysicalstatus} value="No" />
                                            No
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-3">
                                            <p><b>Father/Husband Name *</b></p>
                                        </div>
                                        <div className="col-6 col-md-3">
                                            <input type="text" id="ffname" name="fatehrFName" placeholder="First Name"
                                                className="form-control" value={formData.fatehrFName} onChange={handleChange} />
                                        </div>
                                        <div className="col-6 col-md-3">
                                            <input type="text" name="fatehrMName" placeholder="Middle Name"
                                                className="form-control" value={formData.fatehrMName} onChange={handleChange} />
                                        </div>
                                        <div className="col-6 col-md-3">
                                            <input type="text" name="fatehrLName" placeholder="Last Name"
                                                className="form-control" value={formData.fatehrLName} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-3">
                                            <p><b>Marital Status *</b>(In case of married women Husband's Name)</p>
                                        </div>
                                        <div className="col-6 col-md-9">
                                            <input type="radio" className="mt" name="maritalStatus" value="Single" onChange={handleMaritalStatusChange} checked={maritalStatus === "Single"} />
                                            Single&nbsp;&nbsp;&nbsp;
                                            <input type="radio" className="mt" name="maritalStatus" value="Married" onChange={handleMaritalStatusChange} checked={maritalStatus === "Married"} />
                                            Married&nbsp;&nbsp;&nbsp;
                                            <input type="radio" className="mt" name="maritalStatus" value="Widow (er)" onChange={handleMaritalStatusChange} checked={maritalStatus === "Widow (er)"} /> Widow
                                            (er)&nbsp;&nbsp;&nbsp;
                                            <input type="radio" className="mt" name="maritalStatus" value="Divorce" onChange={handleMaritalStatusChange} checked={maritalStatus === "Divorce"} /> Divorce
                                        </div>
                                    </div>
                                    {maritalStatus === "Married" && (
                                        <div className="row mtrs">
                                            <div className="col-6 col-md-3">
                                                <p><b>Spouse Name *</b></p>
                                            </div>
                                            <div className="col-6 col-md-3">
                                                <input id="mtrg" type="text" name="spouseFName" placeholder="Spouse First Name"
                                                    className="form-control mtrg" value={formData.spouseFName} onChange={handleChange} required={maritalStatus === "Married"} />
                                            </div>
                                            <div className="col-6 col-md-3">
                                                <input type="text" name="spouseMName" value={formData.spouseMName} onChange={handleChange} placeholder="Spouse Middle Name"
                                                    className="form-control mtrg" />
                                            </div>
                                            <div className="col-6 col-md-3">
                                                <input type="text" name="spouseLName" value={formData.spouseLName} onChange={handleChange} placeholder="Spouse Last Name"
                                                    className="form-control mtrg" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="form-panel m-0">
                                <div className="heading"><i className="fa fa-cap"></i> Applicant's Education Qualification (Minimum
                                    10th pass or Equivalent)</div>
                                <div className="box">
                                    <div className="row">
                                        <div className="col-6 col-md-4">
                                            <p><b>Qualification *</b></p>
                                            <input type="text" name="qualification" className="form-control"
                                                placeholder="Qualification" value={formData.qualification} onChange={handleChange} />
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <p><b>Board/University/Institute *</b></p>
                                            <input type="text" name="board" className="form-control"
                                                placeholder="Board/University/Institute" value={formData.board} onChange={handleChange} />
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <p><b>Degree</b></p>
                                            <input type="text" name="degree" value={formData.degree} onChange={handleChange} className="form-control" placeholder="Degree" />
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <p><b>Year *</b></p>
                                            <select id="ddlYears" name="year" className="form-control" value={formData.year} onChange={handleChange}>
                                                <option value="">Select Year</option>
                                                <option value="1950">1950</option>
                                                <option value="1951">1951</option>
                                                <option value="1952">1952</option>
                                                <option value="1953">1953</option>
                                                <option value="1954">1954</option>
                                                <option value="1955">1955</option>
                                                <option value="1956">1956</option>
                                                <option value="1957">1957</option>
                                                <option value="1958">1958</option>
                                                <option value="1959">1959</option>
                                                <option value="1960">1960</option>
                                                <option value="1961">1961</option>
                                                <option value="1962">1962</option>
                                                <option value="1963">1963</option>
                                                <option value="1964">1964</option>
                                                <option value="1965">1965</option>
                                                <option value="1966">1966</option>
                                                <option value="1967">1967</option>
                                                <option value="1968">1968</option>
                                                <option value="1969">1969</option>
                                                <option value="1970">1970</option>
                                                <option value="1971">1971</option>
                                                <option value="1972">1972</option>
                                                <option value="1973">1973</option>
                                                <option value="1974">1974</option>
                                                <option value="1975">1975</option>
                                                <option value="1976">1976</option>
                                                <option value="1977">1977</option>
                                                <option value="1978">1978</option>
                                                <option value="1979">1979</option>
                                                <option value="1980">1980</option>
                                                <option value="1981">1981</option>
                                                <option value="1982">1982</option>
                                                <option value="1983">1983</option>
                                                <option value="1984">1984</option>
                                                <option value="1985">1985</option>
                                                <option value="1986">1986</option>
                                                <option value="1987">1987</option>
                                                <option value="1988">1988</option>
                                                <option value="1989">1989</option>
                                                <option value="1990">1990</option>
                                                <option value="1991">1991</option>
                                                <option value="1992">1992</option>
                                                <option value="1993">1993</option>
                                                <option value="1994">1994</option>
                                                <option value="1995">1995</option>
                                                <option value="1996">1996</option>
                                                <option value="1997">1997</option>
                                                <option value="1998">1998</option>
                                                <option value="1999">1999</option>
                                                <option value="2000">2000</option>
                                                <option value="2001">2001</option>
                                                <option value="2002">2002</option>
                                                <option value="2003">2003</option>
                                                <option value="2004">2004</option>
                                                <option value="2005">2005</option>
                                                <option value="2006">2006</option>
                                                <option value="2007">2007</option>
                                                <option value="2008">2008</option>
                                                <option value="2009">2009</option>
                                                <option value="2010">2010</option>
                                                <option value="2011">2011</option>
                                                <option value="2012">2012</option>
                                                <option value="2013">2013</option>
                                                <option value="2014">2014</option>
                                                <option value="2015">2015</option>
                                                <option value="2016">2016</option>
                                                <option value="2017">2017</option>
                                                <option value="2018">2018</option>
                                                <option value="2019">2019</option>
                                                <option value="2020">2020</option>
                                                <option value="2021">2021</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                            </select>
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <p><b>Group Type *</b></p>
                                            <select onChange={handleGroupStatusChange} name="grouptype" className="form-control gp"
                                                required>
                                                <option value="">Select Type</option>
                                                <option value="Group 1">Group 1</option>
                                                <option value="Group 2">Group 2</option>
                                                <option value="Group 3">Group 3</option>
                                            </select>
                                        </div>
                                    </div>
                                    {selectedGroup !== 'Group 3' && (
                                        <>
                                            <div className="row gphide">
                                                <div className="col-6 col-md-3">
                                                    <p className="leasehodler"><b>Name(s) of the owner of Land/Leaseholder *</b></p>
                                                    <input type="text" name="leaseholder" value={formData.leaseholder} onChange={handleChange} className="form-control gprv" />
                                                </div>
                                                <div className="col-6 col-md-3">
                                                    <p className="leasehodler"><b>Relationship with Application *</b></p>
                                                    <select name="relation" value={formData.relation} onChange={handleChange} className="form-control gprv" required>
                                                        <option value="">Relationship with Application</option>
                                                        <option value="Self">Self</option>
                                                        <option value="Spouse">Spouse</option>
                                                        <option value="Father">Father</option>
                                                        <option value="Mother">Mother</option>
                                                        <option value="Step Father">Step Father</option>
                                                        <option value="Step Mother">Step Mother</option>
                                                        <option value="Brother/Sister">Brother/Sister</option>
                                                        <option value="Step Brother/Sister">Step Brother/Sister</option>
                                                        <option value="Son/Daughter/Step, Son/Step Daughter">Son/Daughter/Step,
                                                            Son/Step Daughter</option>
                                                        <option value="Son-in-law / Daughter-in-law">Son-in-law / Daughter-in-law
                                                        </option>
                                                        <option value="Parents-in-law">Parents-in-law</option>
                                                        <option value="Grand Parents (Both Maternal &amp; Paternal)">Grand Parents
                                                            (Both Maternal &amp; Paternal)</option>
                                                    </select>
                                                </div>
                                                <div className="col-6 col-md-3">
                                                    <p className="leasehodler"><b>Date of registration sale deed / Gift deed / lease
                                                        deed / date of mutation *</b></p>
                                                    <input type="date" name="dateregistration" value={formData.dateregistration} onChange={handleChange} className="form-control gprv" />
                                                </div>
                                                <div className="col-6 col-md-3">
                                                    <p className="leasehodler"><b>Khasra No / Khatouni / Gut No / Survey No. *</b></p>
                                                    <input type="text" name="khasrano" value={formData.khasrano} onChange={handleChange} className="form-control gprv" />
                                                </div>
                                                <div className="col-6 col-md-12">
                                                    <p><b>Location of the Land *</b></p>
                                                    <input type="text" name="locationoftheland" onChange={handleChange} value={formData.locationoftheland} className="form-control gprv"
                                                        placeholder="Fill the complete address of the land" />
                                                </div>
                                            </div>
                                            <div className="row gphide">
                                                <div className="col-6 col-md-12">
                                                    <p><b>Dimension of Land *</b></p>
                                                </div>
                                                <div className="col-6 col-md-3">
                                                    <p><b>Frontage in Meter *</b></p>
                                                    <input type="text" name="frontage" value={formData.frontage} onChange={handleChange} className="form-control gprv" />
                                                </div>
                                                <div className="col-6 col-md-3">
                                                    <p><b>Depth in Meter *</b></p>
                                                    <input type="text" name="depth" value={formData.depth} onChange={handleChange} className="form-control gprv" />
                                                </div>
                                                <div className="col-6 col-md-3">
                                                    <p><b>Area (Sq.M) *</b></p>
                                                    <input type="text" name="area" value={formData.area} onChange={handleChange} className="form-control gprv" />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="row">
                                        <div className="col-6 col-md-7">
                                            <p><b>are you efilling to transfer the land on sale / long lease of the CNG Pump
                                                *</b></p>
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <select name="dealershipchayan" value={formData.dealershipchayan} onChange={handleChange} className="form-control">
                                                <option value="">Select Type</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-4">
                                            <p><b>Please specify rate(RS) term *</b></p>
                                        </div>
                                        <div className="col-6 col-md-5">
                                            <textarea name="specifyrate" value={formData.specifyrate} onChange={handleChange} className="form-control"
                                                placeholder="“If you want to give the CNG Pump to company to operate it, write rate terms on annual basis. If not, write NO"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-4">
                                            <p><b>Do you want to offer another plot of land under? *</b></p>
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <input type="radio" name="landunder" onChange={handlelandunderstatus} value="Yes" required="" />
                                            Yes&nbsp;&nbsp;&nbsp;<input type="radio" name="landunder" onChange={handlelandunderstatus} value="No" /> No
                                        </div>
                                        <div className="col-6 col-md-2">
                                            <p><b>Create Password *</b></p>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <input type="password" name="password" value={formData.password} onChange={handleChange} placeholder="Create your login password"
                                                className="form-control" required="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-panel m-0">
                                <div className="heading"><i className="fa fa-check"></i> Declaration</div>
                                <p className="authorized">
                                    I, <span id="namespan">....................</span> of/son
                                    of/wife of Shri <span id="fnamespan"
                                    >....................</span> hereby confirm that I have been
                                    authorized to submit this application on behalf of M/s .................... and the
                                    information given above is true and correct. Any wrong
                                    information/misrepresentation/suppression of facts will make us ineligible for this
                                    Dealership. That if any information/declaration given by us in application or in any
                                    document submitted by us in support of application for award of the dealership shall be
                                    found to be untrue or incorrect or false, the Company would be within its rights to
                                    withdraw/terminate letter of intent of the dealership (if already appointed) and that we
                                    would have no claim, whatsoever, against the Corporation for such
                                    withdrawal/termination.
                                </p>
                                <center><input type="checkbox" required="" /> Accept *<br /><br /></center>
                            </div>
                            <div className="form-panel m-0">
                                <div className="heading"><i className="fa fa-image"></i> Applicant Photo</div>
                                <div className="box">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p><b>Applicant Photo *</b></p>
                                            <input type="file" className="form-control heightauto" onChange={handleImageChange} name="image1" accept="image/*"
                                            />
                                            <span className="photofont">Formate jpg only, Max Size 50kb, Dimension 5.5cm X
                                                5.5cm</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-panel m-0">
                                <div className="heading"><i className="fa fa-file"></i> Applicant ID and Address Proof</div>
                                <div className="box">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p><b>Applicant ID Proof *</b></p>
                                            <input type="file" className="form-control heightauto" onChange={handleImageChange} accept="image/*" name="image2"
                                            />
                                            <span className="photofont">Formate jpg only, Max Size 100kb, Dimension 5.5cm
                                                X 5.5cm</span>
                                        </div>
                                        <div className="col-md-6">
                                            <p><b>Applicant Address Proof *</b></p>
                                            <input type="file" className="form-control heightauto" onChange={handleImageChange} accept="image/*" name="image3"
                                            />
                                            <span className="photofont">Formate jpg only, Max Size 100kb, Dimension 5.5cm
                                                X 5.5cm</span>
                                        </div>
                                        <div className="col-6 col-sm-6">
                                            <button type="submit"
                                                className="btn btn-primary mt-20 margintopsutton">Submit</button>
                                        </div>
                                        <div className="col-6 col-sm-6 text-right">
                                            <a href="/register"
                                                className="btn btn-primary mt-20 margintopsutton">Back</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Formsubmit;