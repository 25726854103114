import React from "react";
import { Link } from 'react-router-dom';
import homeBanner from '../images/homebanner.webp'
import gif from '../images/new-flash-gif.gif'
import Headerlogin from "./Headerlogin";

const Index = () => {
    return (
        <div>
            <Headerlogin />

            <section class="content">
                <div class="container">
                    <div class="row wt">
                        <div class="col-md-12">
                            <div class="banner">
                                <div class="row wt">
                                    <div class="col-md-8">
                                        <img src={homeBanner} alt="Background" />
                                    </div>
                                    <div class="col-md-4">
                                        <div class="whats-new">
                                            <h3><span>Advertisement Updates</span></h3>
                                            <div id="categories">
                                                <div id="inner_categories">
                                                    <ul>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Andaman Nicobar - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Andhra Pradesh - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Arunachal Pradesh - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Assam - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Bihar - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Chandigarh - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Chhattisgarh - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Dadra Nagar Haveli - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Daman &amp; Diu - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Delhi - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Goa - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Gujarat - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Haryana - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Himachal Pradesh - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Jammu &amp; Kashmir -
                                                            <span >Open <img
                                                                src={gif} alt="gif"
                                                            /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Ladakh - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Jharkhand - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Karnataka - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Kerala - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Madhya Pradesh - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Maharashtra - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Manipur - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Meghalaya - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Mizoram - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Nagaland - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Odisha - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Pondicherry - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Punjab - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Rajasthan - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Sikkim - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Tamil Nadu - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Telangana - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Tripura - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Uttar Pradesh - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in Uttarakhand - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                        <li><Link to="/register">Advertisement
                                                            Information of HPCL Petrol Pump Dealership in West Bengal - <span
                                                            >Open <img
                                                                    src={gif} alt="gif"
                                                                /></span></Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bottom-links-b">
                                            <ul>
                                                <li><Link to="/advertisements/viewall">Apply
                                                    Now</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 homenotice">
                            <strong>Dealership Notice :</strong>
                            <marquee>Applicants should note:- Last date for
                                online application form is 10th October 2023, kindly deposit the application fee or other
                                charges on www.hindustanpetroleumcoporation.in only, we will not be responsible for the amount
                                deposited to agent or place.</marquee>
                        </div>
                    </div>

                    <div class="row wt">
                        <div class="col-md-8 perag">
                            <h2>HPCL Petrol Pump Dealership Application Portal</h2>
                            <p>You are strongly advised to submit only one application on the portal
                                for one location. If multiple application forms are submitted for the same applicant at the same
                                location, only the last application will be considered in the selection process. An applicant
                                can make multiple applications for different locations but it will be mandatory to deposit the
                                fee for each application,&nbsp;If the applicant fails to deposit the application fee before the
                                last date then the application will be treated as incomplete and such applications will be
                                rejected.</p>

                            <p>To complete the application it is mandatory to submit the refundable
                                application fee along with the Id proof and address proof of the applicant, the application fee
                                will be refunded only in the direction of non-selection and&nbsp;the fee refund process will be
                                completed within 7 days of closure of the application form window.</p>
                            <div class="links">
                                <ul>

                                    <li><Link to="/divisionaloffice"><b>Contact us: </b> Regional
                                        Offices</Link></li>
                                    <li><Link>Application Process Brochure Instructions </Link></li>
                                    <li><Link>The Important Information including investments. <img src={gif} alt="gif"
                                    /></Link></li>

                                    <li><Link to="/track">Check Result Online <img
                                        src={gif} alt="gif" /></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="imp-link hpcl">
                                <h3> <span>Important Dates</span></h3>
                                <ul>
                                    
                                    <li><Link>सीएनजी पंप विज्ञापन / HPCL Petrol Pump Advertisements </Link></li>
                                    <li><Link>सीएनजी पंप आवेदन की अंतिम तिथि 10 अक्टूबर 2023 है / Last date for HPCL Petrol Pump
                                        application is 10 October 2023 </Link></li>
                                    <li><Link>सीएनजी पंप आवेदन शुल्क जमा करने की अंतिम तिथि 10 अक्टूबर 2023 है / Last date for
                                        submission of HPCL Petrol Pump application fee is 10 October 2023 </Link></li>
                                    <li><Link>आवेदन फॉर्म भरने के लिए निर्देश / Instructions for filling the application form </Link>
                                    </li>
                                    <li><Link>सीएनजी पंप डीलरशिप रिजल्ट 15 अक्टूबर 2023 को ऑनलाइन मोड में जारी किया जाएगा। सीएनजी
                                        पंप रिजल्ट चेक ऑनलाइन लिंक आधिकारिक वेबसाइट  पर
                                        उपलब्ध कराया जाएगा / HPCL Petrol Pump Dealership Result will be released in online mode on
                                        15 October 2023. HPCL Petrol Pump Result Check Online link will be provided on the official
                                        website . </Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Index;