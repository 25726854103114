import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getDatabase, ref, update } from "firebase/database";
import Header from "./Header";
import RedirectLink from "./redirectlink";

const ChangePassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [repassword, setRepassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(""); // Reset the error message
        setSuccess(""); // Reset the success message

        // Basic validation
        if (password !== repassword) {
            setError("Passwords do not match.");
            return;
        }

        if (password.length < 6) {
            setError("Password must be at least 6 characters long.");
            return;
        }

        const db = getDatabase();
        const userRef = ref(db, `registrations/${location.state.id}`);

        try {
            await update(userRef, {
                password: password
            });

            setSuccess("Password updated successfully.");
            setTimeout(() => {
                navigate("/login"); // Redirect to login or any other page
            }, 2000);
        } catch (error) {
            console.error(error);
            setError("Error updating password. Please try again later.");
        }
    };

    return (
        <div>
            <Header />
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <RedirectLink />
                    </div>
                    <div className="col-md-6 offset-3">
                        <div className="panel">
                            {/* Display success or error messages */}
                            {error && <div className="alert alert-danger">{error}</div>}
                            {success && <div className="alert alert-success">{success}</div>}

                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <i className="form-icon fa fa-lock" />
                                        <input
                                            type="password"
                                            name="password"
                                            className="form-control p-35"
                                            placeholder="Enter Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <i className="form-icon fa fa-lock" />
                                        <input
                                            type="password"
                                            name="repassword"
                                            className="form-control p-35"
                                            placeholder="Re-Enter Password"
                                            value={repassword}
                                            onChange={(e) => setRepassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="col-sm-12">
                                        <button type="submit" className="btn btn-primary mt-20">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
