import React, { useEffect, useState, useRef } from "react";
import Epay from "../images/e-pay.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { onValue, ref } from "firebase/database";
import { database } from "../firebase-config";
import { useReactToPrint } from "react-to-print";

const PaymentReceipt = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userRegistration, setRegistrationData] = useState(null);
  const [latestPayment, setLatestPayment] = useState(null); // Store the latest payment
  const printRef = useRef(); // Reference to the div you want to print

  useEffect(() => {
    if (location.state.id) {
      // Fetch registration data
      const registrationRef = ref(database, `registrations/${location.state.id}`);
      onValue(registrationRef, (snapshot) => {
        const data = snapshot.val();
        setRegistrationData(data);
      });

      // Fetch payment details from the new path
      const paymentsRef = ref(database, `registrations/${location.state.id}/userpayments`);
      onValue(paymentsRef, (snapshot) => {
        const paymentData = snapshot.val();
        if (paymentData) {
          // Get all keys (unique IDs)
          const paymentEntries = Object.entries(paymentData);

          // Sort by key or timestamp (assuming timestamp or key gives latest)
          const latestPaymentEntry = paymentEntries.sort(
            ([idA, dataA], [idB, dataB]) => dataB.timestamp - dataA.timestamp
          )[0];

          setLatestPayment(latestPaymentEntry[1]); // Get the data of the latest entry
        }
      });
    }
  }, [location.state.id]);

  const handlePayment = () => {
    navigate("/myaccount", { state: { id: location.state.id } });
  };

  // Use the useReactToPrint hook to handle printing
  const handlePrint = useReactToPrint({
    content: () => printRef.current, // Only print the specific div
  });

  return (
    <div className="container">
      <div ref={printRef} className="row print_div">
        <div id="content" className="col-md-12">
          <div className="text-center" style={{ marginTop: "40px" }}>
            <img src={Epay} style={{ width: "200px" }} alt="Epay" />
          </div>
          <div className="form-panel m-0" style={{ background: "#fff" }}>
            <div className="heading">
              We have received your transaction ID. We will inform you about your payment details soon.
            </div>
            <div className="box" style={{ padding: "0 15px" }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="row" style={{ background: "#eee", padding: "10px 0px" }}>
                    <div className="col-md-3"><b>Application Number</b></div>
                    <div className="col-md-9">{userRegistration && <span>{userRegistration.ApplicationId}</span>}</div>
                  </div>
                  <div className="row" style={{ padding: "10px 0" }}>
                    <div className="col-md-3"><b>Name of Location</b></div>
                    <div className="col-md-9">{userRegistration && <span>{userRegistration.location}</span>}</div>
                  </div>
                  <div className="row" style={{ background: "#eee", padding: "10px 0px" }}>
                    <div className="col-md-3"><b>Amount</b></div>
                    <div className="col-md-9">{latestPayment && <span>{latestPayment.amount}</span>}</div>
                  </div>
                  <div className="row" style={{ padding: "10px 0" }}>
                    <div className="col-md-3"><b>Payment Date</b></div>
                    <div className="col-md-9">{latestPayment && <span>{latestPayment.paymentdate}</span>}</div>
                  </div>
                  <div className="row" style={{ background: "#eee", padding: "10px 0px" }}>
                    <div className="col-md-3"><b>Status</b></div>
                    <div className="col-md-9" style={{ color: "blue" }}>{latestPayment && <span>{latestPayment.Paymentstatus}</span>}</div>
                  </div>
                  <div className="row receipt" style={{ padding: "10px 0" }}>
                    <div className="col-md-3"><b>Pay Another Amount</b></div>
                    <div className="col-md-9"><a onClick={() => handlePayment()}>Pay Another Amount</a></div>
                  </div>
                  <div className="row" style={{ background: "#eee", padding: "10px 0px" }}>
                    <div className="col-md-12 text-center">
                      If you found anything incorrect in the information, please write to us at: support@hindustanpetroleumcoporation.in
                      <br />
                      <span style={{ color: "blue" }}>
                        We have sent you an email carrying your application number. Please check your email for further details.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center receipt">
          <button onClick={handlePrint} style={{ margin: "10px 0", display: "inline-block" }}>Print</button>
        </div>
      </div>
    </div>
  );
};

export default PaymentReceipt;
