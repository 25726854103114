import React from "react";
import './Header.css'
import logo from '../images/hp-logo.png'
import { useNavigate } from "react-router-dom";


const Headerlogin = () => {

    const navigate = useNavigate();
    const handleregister = () => {
        navigate("/register")

    }
    const handleLogin = () => {
        navigate("/login")

    }

    return (
        <div>
            <div className="top-border"></div>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="logo">
                                <a>
                                    <img src={logo} alt="Logo" />
                                </a>
                            </div>
                            <div className="logo">
                                <a>
                                    <h4><span>HPCL Petrol Pump Dealership</span></h4>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="login-btn">
                                <ul>
                                    <li><a onClick={() => handleregister()} className="blue"><i
                                        className="fa fa-user"></i> Apply HPCL</a></li>
                                    <li><a onClick={() => handleLogin()} className="border-btn"><i
                                        className="fa fa-cog"></i> Applicant Login</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
};

export default Headerlogin;