import React from "react";
import RedirectLink from "./redirectlink";
import Header from "./Header";
const Nocpage = () => {
    return (
        <div>
            <Header />

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <RedirectLink />
                        <div className="form-panel m-0" style={{ paddingTop: '10px' }}>
                            <div id="example_wrapper" className="dataTables_wrapper no-footer">
                                <div className="dataTables_length" id="example_length"><label>Show <select name="example_length" aria-controls="example" className>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select> entries</label></div>
                                <div id="example_filter" className="dataTables_filter"><label>Search:<input type="search" className placeholder="Search" aria-controls="example" /></label></div>
                                <table id="example" className="table table-striped table-bordered dataTable no-footer" aria-describedby="example_info" style={{ width: '100%' }} width="100%" cellSpacing={0}>
                                    <thead>
                                        <tr>
                                            <th className="text-center sorting sorting_asc" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} style={{ width: '115.4px' }} aria-sort="ascending" aria-label="S. No.: activate to sort column descending">S. No.</th>
                                            <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} style={{ width: '182.4px' }} aria-label="Name of Location: activate to sort column ascending" width="20%">Name of Location</th>
                                            <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} style={{ width: '94.4px' }} aria-label="Date: activate to sort column ascending">
                                                Date</th>
                                            <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} style={{ width: '201.4px' }} aria-label="NOC Status: activate to sort column ascending">NOC Status</th>
                                            <th className="sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} style={{ width: '177.4px' }} aria-label="Valid upto: activate to sort column ascending">Valid upto</th>
                                            <th className="text-center sorting" tabIndex={0} aria-controls="example" rowSpan={1} colSpan={1} style={{ width: '122.4px' }} aria-label="Status: activate to sort column ascending">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="odd">
                                            <td colSpan={6} className="dataTables_empty" valign="top">No data available in table
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="dataTables_info" id="example_info" role="status" aria-live="polite">Showing 0 to
                                    0 of 0 entries</div>
                                <div className="dataTables_paginate paging_simple_numbers" id="example_paginate"><a className="paginate_button previous disabled" aria-controls="example" data-dt-idx={0} tabIndex={-1} id="example_previous">Previous</a><span /><a className="paginate_button next disabled" aria-controls="example" data-dt-idx={1} tabIndex={-1} id="example_next">Next</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Nocpage;