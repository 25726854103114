import React, { useState, useEffect } from "react";
import { ref, onValue, push, update } from "firebase/database"; // Import 'push' for generating unique ID
import { useNavigate } from "react-router-dom"; // For navigation after form submission
import { database } from '../firebase-config';
import Headerlogin from "./Headerlogin";

const Register = () => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const navigate = useNavigate(); // To navigate to a new page after form submission

  // Fetch countries from Firebase
  useEffect(() => {
    const fetchCountries = () => {
      const countriesRef = ref(database, 'countries');
      onValue(countriesRef, (snapshot) => {
        const data = snapshot.val();
        const countryList = data ? Object.keys(data) : [];
        setCountries(countryList);
      });
    };
    fetchCountries();
  }, []);

  // Fetch states based on selected country
  const fetchStates = (country) => {
    setSelectedCountry(country);
    const statesRef = ref(database, `countries/${country}`);
    onValue(statesRef, (snapshot) => {
      const data = snapshot.val();
      const stateList = data ? Object.values(data) : [];
      setStates(stateList);
      setCities([]);
      setSelectedState('');
    });
  };

  // Fetch cities based on selected state
  const fetchCities = (state) => {
    setSelectedState(state);
    const citiesRef = ref(database, `cities/${state}`);
    onValue(citiesRef, (snapshot) => {
      const data = snapshot.val();
      const cityList = data ? Object.values(data) : [];
      setCities(cityList);
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
  
    const registrationsRef = ref(database, 'registrations');
    const newRegistrationRef = push(registrationsRef);
  
    const formData = {
      state: selectedCountry,
      district: selectedState,
      location: selectedCity,
    };
  
    // Save data to Firebase and pass reference key in state
    update(newRegistrationRef, formData)
      .then(() => {
        navigate('/formsubmit', { state: { id: newRegistrationRef.key } });  // Pass reference ID in state
      })
      .catch((error) => {
        console.error("Error submitting form: ", error);
      });
  };

  return (
    <div>
      <Headerlogin />
    
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-3">
            <div className="panel">
              <h3>Applicant Dealership Registration Panel</h3>
              <form id="locationForm" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <label className="control-label">
                      State <span className="red-btn">*</span>
                    </label>
                    <select
                      name="country"
                      id="country"
                      onChange={(e) => fetchStates(e.target.value)}
                      className="form-control"
                      required
                    >
                      <option value="">Select</option>
                      {countries.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-12">
                    <label className="control-label">
                      District <span className="red-btn">*</span>
                    </label>
                    <select
                      name="state"
                      id="state"
                      onChange={(e) => fetchCities(e.target.value)}
                      className="form-control"
                      disabled={!selectedCountry}
                      required
                    >
                      <option value="">Select</option>
                      {states.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-12">
                    <label className="control-label">
                      Location <span className="red-btn">*</span>
                    </label>
                    <select
                      name="city"
                      id="city"
                      className="form-control"
                      disabled={!selectedState}
                      onChange={(e) => setSelectedCity(e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      {cities.map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-12">
                    <button type="submit" className="btn btn-primary mt-20">
                      Apply
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default Register;
