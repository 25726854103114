
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Register from './components/Register';
import Submitted from './components/Submited';
import NextPage from './components/Next-page';
import Formsubmit from './components/Formsubmit';
import Myaccount from './components/Myaccount';
import ViewDetails from './components/Viewdetails';
import UploadDocument from './components/UploadDocument';
import SecurityPage from './components/security';
import ChanglePassword from './components/changepassword';
import DownloadDocument from './components/downloadDocument';
import Invoice from './components/invoice';
import Nocpage from './components/noc';
import FinalApproval from './components/FinalApproval';
import PaymentReceipt from './components/PaymentReceipt';
import Agreement from './components/agreement';
import Index from './components';
import Payment from './components/OnlinePayment';
import Registration from './components/Registration';
import Insurance from './components/Insurance';
import Login from './components/Login';
import Licence from './components/Licence';
import Latefine from './components/Latefine';
import Dealership from './components/Dealership';
import './css/bootstrap.min.css'
import './css/dataTable.css'
import './css/style.css'
import './css/responsive.css'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/register" element={<Register />} />
        <Route path="/submitted" element={<Submitted />} />
        <Route path="/next-page" element={<NextPage />} />
        <Route path="/formsubmit" element={<Formsubmit />} />
        <Route path="/myaccount" element={<Myaccount />} />
        <Route path="/viewdetails" element={<ViewDetails />} />
        <Route path="/documentupload" element={<UploadDocument />} />
        <Route path="/noc" element={<Nocpage />} />
        <Route path="/security" element={<SecurityPage />} />
        <Route path="/changepassword" element={<ChanglePassword />} />
        <Route path="/downloaddocument" element={<DownloadDocument />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/finalapproval" element={<FinalApproval />} />
        <Route path="/agreement" element={<Agreement />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registrationfile" element={<Registration />} />
        <Route path="/paymentreceipt" element={<PaymentReceipt />} />
        <Route path="/dealership" element={<Dealership />} />
        <Route path="/licence" element={<Licence />} />
        <Route path="/latefine" element={<Latefine />} />
        <Route path="/insurance" element={<Insurance />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
