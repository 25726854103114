import React, { useState, useEffect } from "react";
import RedirectLink from "./redirectlink";
import Header from "./Header";
import { database, storage } from "../firebase-config"; // Import Firebase
import { ref as databaseRef, push, onValue, remove, set } from "firebase/database";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage"; // Firebase storage for file upload
import { useLocation } from "react-router-dom";
import Loader from './Loader'; // Import the Loader component

const UploadDocument = () => {
    const location = useLocation();
    const [documents, setDocuments] = useState([]); // To store all uploaded documents
    const [file, setFile] = useState(null); // To handle file upload
    const [documentName, setDocumentName] = useState(""); // To handle document name input
    const [loading, setLoading] = useState(false); // Loading state

    // Fetch documents from Firebase when the component mounts
    useEffect(() => {
        if (location.state && location.state.id) {
            const docRef = databaseRef(database, `registrations/${location.state.id}/documents`);
            onValue(docRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    const formattedData = Object.keys(data).map((key, index) => ({
                        id: key,
                        sNo: index + 1,
                        ...data[key],
                    }));
                    setDocuments(formattedData);
                } else {
                    setDocuments([]); // Clear the list if no documents are found
                }
            });
        }
    }, [location.state.id]);

    // Handle file selection
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Handle document upload
    const handleUpload = async (e) => {
        e.preventDefault();
        if (!file || !documentName) return;

        setLoading(true); // Show the loader

        try {
            // Generate a unique ID for the document
            const uniqueId = push(databaseRef(database, `registrations/${location.state.id}/documents`)).key;
            const fileRef = storageRef(storage, `documents/${uniqueId}-${file.name}`);

            await uploadBytes(fileRef, file); // Upload the file to Firebase Storage
            const fileUrl = await getDownloadURL(fileRef); // Get the file's URL

            const newDocument = {
                documentName,
                documentType: file.type,
                uploadDate: new Date().toLocaleDateString("en-GB"), // Format: dd/mm/yyyy
                status: "In Process",
                fileUrl,
            };

            // Save the document using `set` under the generated unique ID
            const documentRef = databaseRef(database, `registrations/${location.state.id}/documents/${uniqueId}`);
            await set(documentRef, newDocument); // Save document info

            setFile(null);
            setDocumentName("");
        } catch (error) {
            console.error("Error uploading document:", error);
        } finally {
            setLoading(false); // Hide the loader
        }
    };

    // Handle document deletion
    const handleDelete = (docId) => {
        const documentRef = databaseRef(database, `registrations/${location.state.id}/documents/${docId}`);

        // Remove document from Firebase
        remove(documentRef).then(() => {
            // Update the state by filtering out the deleted document
            setDocuments((prevDocuments) => prevDocuments.filter(doc => doc.id !== docId));
        }).catch((error) => {
            console.error("Error deleting document:", error);
        });
    };

    // Handle document view in a popup
    const handleView = (fileUrl) => {
        window.open(fileUrl, "_blank");
    };

    return (
        <div>
            <Header />
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <RedirectLink />
                        <div className="form-panel m-0" style={{ paddingTop: '10px' }}>
                            {loading && <Loader />} {/* Show loader while uploading */}
                            <form id="myForm" className="form-horizontal form-border" onSubmit={handleUpload} encType="multipart/form-data">
                                <div className="form-group" style={{ padding: '0 10px' }}>
                                    <div className="row">
                                        <div className="col-sm-5">
                                            <label className="control-label">Document Name <span className="red-btn">*</span></label>
                                            <input type="text" value={documentName} onChange={(e) => setDocumentName(e.target.value)} className="form-control" required />
                                        </div>
                                        <div className="col-sm-5">
                                            <label className="control-label">Document <span className="red-btn">*</span></label>
                                            <input type="file" onChange={handleFileChange} className="form-control" required />
                                        </div>
                                        <div className="col-sm-2">
                                            <button type="submit" className="btn btn-primary w-100" style={{ marginTop: '28px' }}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>S. No.</th>
                                        <th>Document Name</th>
                                        <th>Document Type</th>
                                        <th>Upload On</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {documents.map((doc, index) => (
                                        <tr key={doc.id}>
                                            <td>{doc.sNo}</td>
                                            <td>{doc.documentName}</td>
                                            <td>{doc.documentType}</td>
                                            <td>{doc.uploadDate}</td>
                                            <td>{doc.status}</td>
                                            <td>
                                                <button onClick={() => handleView(doc.fileUrl)} style={{ margin: '5px' }} className="btn btn-primary">View</button>
                                                <button onClick={() => handleDelete(doc.id)} className="btn btn-danger" style={{ margin: '5px' }}>Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div>
                                {documents.length === 0 && <p>No documents uploaded yet.</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadDocument;
